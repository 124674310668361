/// Width and Height
/// Utilities for setting the width of an element.

.h-main {
  height: var(--main-height);
}

.min-h-main {
  min-height: var(--main-height);
}

.max-h-main {
  max-height: var(--main-height);
}

$box-sizes: (
  w: width,
  h: height,
);

@each $rule, $attribute in $box-sizes {
  // extends based on parent
  .#{$rule}-auto {
    #{$attribute}: auto;
  }
  .#{$rule}-full {
    #{$attribute}: 100%;
  }
  .#{$rule}-screen {
    #{$attribute}: 100vw;
  }
  // extends based on content
  .#{$rule}-min {
    #{$attribute}: min-content;
  }
  .#{$rule}-max {
    #{$attribute}: max-content;
  }
  .#{$rule}-fit {
    #{$attribute}: fit-content;
  }
  // 0 or 1 px
  .#{$rule}-0 {
    #{$attribute}: 0px;
  }
  .#{$rule}-px {
    #{$attribute}: 1px;
  }
  // inherit
  .#{$rule}-inherit {
    #{$attribute}: inherit;
  }
  .min-#{$rule}-inherit {
    min-#{$attribute}: inherit;
  }
  .max-#{$rule}-inherit {
    max-#{$attribute}: inherit;
  }
}

/// CHAR
$sizes: 20;

@for $i from 1 through $sizes {
  .w-#{$i}ch {
    width: #{$i + 'ch'};
  }
  .max-w-#{$i}ch {
    max-width: #{$i + 'ch'};
  }
}

/// REM
$sizes: 40;
$step: 0.25;

@for $i from 0 through $sizes {
  // => Width, height
  .w-#{$i} {
    width: #{$i * $step + 'rem'};
  }
  .h-#{$i} {
    height: #{$i * $step + 'rem'};
  }
  // => Min-Width, Min-height
  .min-w-#{$i} {
    min-width: #{$i * $step + 'rem'};
  }
  .h-#{$i} {
    height: #{$i * $step + 'rem'};
  }
}

// Max-Width, Max-height
// Utilities for setting the maximum width of an element.
// max-width, max-height
$sizes: 8;
$step: 1;

@for $i from 0 through $sizes {
  .max-h-#{$i}rem {
    max-height: #{$i * $step + 'rem'};
  }

  .max-w-#{$i}rem {
    max-width: #{$i * $step + 'rem'};
  }
}

.max-w-min {
  max-width: min-content;
}

.max-w-max {
  max-width: max-content;
}

.max-w-fit {
  max-width: fit-content;
}

// Use w-{fraction} to set an element to a percentage based width.
@each $rule, $attribute in $box-sizes {
  @for $i from 2 through 5 {
    .#{$rule}-1\/#{$i} {
      #{$attribute}: #{(1 / $i * 100) + '%'};
    }

    .#{$rule}-2\/#{$i + 1} {
      #{$attribute}: #{(2 / ($i + 1) * 100) + '%'};
    }

    .#{$rule}-3\/#{$i + 2} {
      #{$attribute}: #{(3 / ($i + 2) * 100) + '%'};
    }
  }
}
