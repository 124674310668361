.list {
  display: none;
  opacity: 0;
  z-index: 20000;
  list-style: none;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  margin: 0;
  padding: 0;
  padding-block: 0.5rem;

  font-size: 1rem;
  width: max-content;
  min-width: 150px;

  &[data-direction='right'] {
    transform-origin: top right;
  }

  &[data-direction='left'] {
    transform-origin: top left;
  }

  &[data-animate-hidden='true'] {
    display: grid;
    animation: hide-menu 0.3s ease-in forwards;
    pointer-events: none;
    @keyframes hide-menu {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
        display: none;
      }
    }
  }

  &[data-is-open='true'] {
    display: grid;
    opacity: 1;
    animation: show-menu 250ms ease-out forwards;
    @keyframes show-menu {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1);
      }
    }
  }

  li {
    padding-inline: 1rem;
    cursor: pointer;
    letter-spacing: 0px;
    color: var(--gray-700);

    &[data-disabled='true'] {
      cursor: not-allowed;
      color: var(--gray-400);
      &:hover {
        text-decoration: none;
      }
    }

    a {
      width: 100%;
      color: inherit;
      &:hover {
        color: #191919;
        text-decoration: underline;
      }
    }
  }
}
