.hidden {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

// grid

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

/// Grid Template Columns
/// Utilities for specifying the columns in a grid layout.
$count: 12;
$step: 1;

@for $i from 1 through $count {
  .grid-cols-#{$i} {
    grid-template-columns: repeat($i, 1fr);
  }
}

.grid-cols-\[1fr_4fr\] {
  grid-template-columns: 1fr 4fr;
}

.grid-cols-\[2fr_7fr\] {
  grid-template-columns: 2fr 7fr;
}

// flex

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

/// Flex Order
/// Utilities for controlling the order of flex and grid items.
$count: 12;
$step: 1;

@for $i from 1 through $count {
  .order-#{$i} {
    order: $i;
  }
}

.order-first {
  order: -9999;
}
.order-last {
  order: 9999;
}
.order-none {
  order: 0;
}

/// Flex Basis
/// Utilities for controlling the initial size of flex items.
.basis-1\/3 {
  flex-basis: 33.333333%;
}

/// Flex Direction
/// Utilities for controlling the direction of flex items.

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

/// Justify Content
/// Utilities for controlling how flex and grid items are positioned along a container's main axis.
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-center {
  justify-content: center;
}

/// Justify Items
/// Utilities for controlling how grid items are aligned along their inline axis.
.justify-items-start {
  justify-items: start;
}
.justify-items-end {
  justify-items: end;
}
.justify-items-center {
  justify-items: center;
}
.justify-items-stretch {
  justify-items: stretch;
}

/// Justify Self
/// Utilities for controlling how an individual grid item is aligned along its inline axis.
.justify-self-auto {
  justify-self: auto;
}
.justify-self-start {
  justify-self: start;
}
.justify-self-end {
  justify-self: end;
}
.justify-self-center {
  justify-self: center;
}
.justify-self-stretch {
  justify-self: stretch;
}

// Align Content
// Utilities for controlling how rows are positioned in multi-row flex and grid containers.

.align-start {
  align-content: start;
}

.content-between {
  align-content: space-between;
}

// Align Items
// Utilities for controlling how flex and grid items are positioned along a container's cross axis.

.items-start {
  align-items: start;
}

.items-end {
  align-items: end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

/// Align Self
/// Utilities for controlling how an individual flex or grid item is positioned along its container's cross axis.

.self-start {
  align-self: start;
}

.self-end {
  align-self: end;
}

/// Place Content
/// Utilities for controlling how content is justified and aligned at the same time.
///
.place-content-center {
  place-content: center;
}

// Place Items
// Utilities for controlling how items are justified and aligned at the same time.
.place-items-start {
  place-items: start;
}
.place-items-end {
  place-items: end;
}
.place-items-center {
  place-items: center;
}
.place-items-baseline {
  place-items: baseline;
}
.place-items-stretch {
  place-items: stretch;
}

/// Place Self
/// Utilities for controlling how an individual item is justified and aligned at the same time.

.place-self-auto {
  place-self: auto;
}
.place-self-start {
  place-self: start;
}
.place-self-end {
  place-self: end;
}
.place-self-center {
  place-self: center;
}
.place-self-stretch {
  place-self: stretch;
}

// Grid Auto Rows
// Utilities for controlling the size of implicitly-created grid rows.

.auto-rows-auto {
  grid-auto-rows: auto;
}
.auto-rows-min {
  grid-auto-rows: min-content;
}
.auto-rows-max {
  grid-auto-rows: max-content;
}
.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

// Gap
// Utilities for controlling gutters between grid and flexbox items.

$counter: 16;
$step: 0.25;

.gap-inherit {
  gap: inherit;
}

@for $i from 0 through $counter {
  .gap-#{$i} {
    gap: #{$i * $step}rem;
  }

  .gap-x-#{$i} {
    column-gap: #{$i * $step}rem;
  }

  .gap-y-#{$i} {
    row-gap: #{$i * $step}rem;
  }
}

/// Isolation
/// Utilities for controlling whether an element should explicitly create a new stacking context.
.isolate {
  isolation: isolate;
}

.isolation-auto {
  isolation: auto;
}

/// Overflow
/// Utilities for controlling how an element handles content that is too large for the container.

$overflow-list: (auto, hidden, scroll, visible);
@each $value in $overflow-list {
  .overflow-#{$value} {
    overflow: $value;
  }

  .overflow-x-#{$value} {
    overflow-x: $value;
  }

  .overflow-y-#{$value} {
    overflow-y: $value;
  }
}

/// Aspect Ratio
/// Utilities for controlling the aspect ratio of an element.
.aspect-auto {
  aspect-ratio: auto;
}
.aspect-square {
  aspect-ratio: 1 / 1;
}
.aspect-video {
  aspect-ratio: 16 / 9;
}
