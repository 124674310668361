.transform-none {
  transform: none;
}

/// Scale
/// Utilities for scaling elements with transform.
.hover\:scale-105 {
  transform: scale(1.05);
}

