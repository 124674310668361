.container {
  border: 1px solid var(--gray-400);
  isolation: isolate;
  overflow: hidden;

  &:has([data-card]:focus),
  &:hover {
    overflow: visible;
  }

  [data-card]:not(:last-child) {
    border-color: var(--gray-400);
    border-style: solid;
    border-bottom-width: 1px;
  }
}
