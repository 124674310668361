.container {
  cursor: pointer;
  user-select: none;
  touch-action: none;
  // box-shadow: 0px 3px 3px #00000029;
  position: relative;
  overflow: visible;
  z-index: 0;

  &:focus {
    box-shadow: unset !important;
    z-index: 1;
  }

  &:hover:not(:focus) {
    background-color: var(--gray-200);
    outline: none !important;
    transition: background-color 1s ease-out;

    [data-chip] {
      background-color: var(--white);
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -0.5rem;
      width: 0.5rem;
      height: 100%;
      background-color: var(--maroon);
      transition: inherit;
    }
  }
}
