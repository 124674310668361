// base
@import './styles/mixins/utils';
@import './styles/mixins/breakpoints';
@import './styles/variables';
@import './styles/colors.scss';
@import './styles/base.scss';
// container
@import './styles/layout.scss';
@import './styles/container.scss';
@import './styles/background.scss';
@import './styles/scrollbar.scss';
// effect
@import './styles/animation.scss';
@import './styles/effect.scss';
@import './styles/transform.scss';
// interactivity
@import './styles/interactivity.scss';
// component
@import './styles/form.scss';
@import './styles/list.scss';
// typography
@import './styles/typography.scss';
// box-model
@import './styles/position.scss';
@import './styles/sizing.scss';
@import './styles/border.scss';
@import './styles/spacing.scss';
// custom
@import './styles/custom';

:root {
  // =============================================
  // Values calculated dynamically by the Navbar
  // =============================================
  --initial-navbar-height: 0px;
  --navbar-height: 0px;
  --top-navbar: 0px;

  --initial-main-height: calc(100vh - var(--initial-navbar-height));
  --height-offset: calc(
    var(--navbar-height, 0px) + var(--top-banner-height, 0px) +
      var(--role-switch-height, 0px)
  );
  --main-height: calc(100vh - var(--height-offset));
  --scroll-margin-top: var(--navbar-height);
  // =============================================
  --timing-function: cubic-bezier(0.19, 1, 0.19, 1);
  --dialog-max-height: calc(100vh - 2em - 6px);
}

*,
:after,
:before {
  box-sizing: border-box;
}

html * {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  // size and space
  margin: 0;
  overflow-y: hidden;
  // text
  font-family: Arial, Helvetica, 'Nimbus Sans L', 'Liberation Sans', FreeSans,
    sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gray-700);
  text-align: left;
  // background
  background-color: var(--body-bg-color);
}

#root {
  height: calc(
    100vh - var(--top-banner-height, 0px) - var(--role-switch-height, 0px)
  );
  overflow-y: auto;
  overflow-x: hidden;
}

main {
  min-height: var(--main-height);
  height: max-content;
}

main,
main.container {
  padding-left: 0;
  padding-right: 0;
}
