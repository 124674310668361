:root {
  // =============================================
  // base colors
  // =============================================
  --transparent: transparent;
  --dark: #000;
  --white: #ffffff;
  --maroon: #8c1d40;
  --gold: #ffc627;
  --green: #78be20;
  --blue: #00a3e0;
  --orange: #ff7f32;
  --red: #ff0000;
  // =============================================
  // green scale
  // =============================================
  --green-300: #86efac;
  --green-400: #4ade80;
  --green-500: #22c55e;
  --green-600: #16a34a;
  --green-700: #15803d;
  --green-800: #166534;
  --green-900: #14532d;
  --green-950: #052e16;

  // =============================================
  // blue scale
  // =============================================
  --blue-300: #93c5fd;
  --blue-400: #60a5fa;
  --blue-500: #3b82f6;
  --blue-600: #2563eb;
  --blue-700: #1d4ed8;
  --blue-800: #1e40af;
  --blue-900: #1e3a8a;
  --blue-950: #172554;

  // =============================================
  // orange scale
  // =============================================
  --orange-100: #ffedd5;
  --orange-200: #fed7aa;
  --orange-300: #fdba74;
  --orange-400: #fb923c;
  --orange-500: #f97316;
  --orange-600: #ea580c;
  --orange-700: #c2410c;
  --orange-800: #9a3412;
  --orange-900: #7c2d12;
  // =============================================
  // gray scale
  // =============================================
  --gray-100: #fafafa;
  --gray-110: #f7f7f7;
  --gray-120: #f6f6f6;
  --gray-130: #f5f5f5;
  --gray-140: #f4f4f4;
  --gray-150: #f3f3f3;
  --gray-160: #f2f2f2;
  --gray-170: #f1f1f1;

  --gray-200: #e8e8e8;
  --gray-300: #d0d0d0;
  --gray-400: #bfbfbf;
  --gray-500: #747474;
  --gray-600: #484848;
  --gray-700: #191919;
  --gray-800: #222222;
  // TODO: to be removed once bg-gray-{number} and border-{*}-gray-{number} will be refactored
  // Color alias
  --gray-1: var(--gray-100);
  --gray-2: var(--gray-200);
  --gray-3: var(--gray-300);
  --gray-4: var(--gray-400);
  --gray-5: var(--gray-500);
  --gray-6: var(--gray-600);
  --gray-7: var(--gray-700);
  --gray-8: var(--gray-800);
  // =============================================
  // alert colors
  // =============================================
  --warning-100: #ffeade;
  --warning-200: #bd4800;
  --warning-210: #bd4815;
  --warning-300: #de2a0d;

  --warning: var(--warning-200);
  --on-warning: var(--gray-100);

  --error-light: #f7dddd;
  --error-dark: #cc2f2f;

  --info: #d6f0fa;
  --on-info: #d6f0fa;
  // =============================================
  // primary/secondary colors
  // =============================================
  --primary: var(--maroon);
  --on-primary: var(--white);

  --secondary: var(--gold);
  --on-secondary: var(--dark);

  --secondary-2-: var(--green);
  --secondary-3: var(--blue);
  // =============================================
  // major colors
  // =============================================
  --current-major-bg-color: var(--primary);
  --on-current-major-bg-color: var(--on-primary);

  --target-major-bg-color: var(--secondary);
  --on-target-major-bg-color: var(--on-secondary);

  --body-bg-color: var(--gray-100);
  // =============================================
  // Misc
  // =============================================
  --link-color: var(--gray-600);
  --link-color-hover: var(--maroon);
  --focus-color: var(--gray-700);
  --disabled-color: var(--gray-400);
}
