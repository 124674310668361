.container {
  border-radius: inherit;
  width: var(--size, inherit);
  height: var(--size, inherit);

  &[data-disabled='true'] {
    cursor: not-allowed;
    color: inherit;
  }
}

#content {
  transition: opacity 0.5s;
  opacity: 0;
  max-width: 295px;
}
