.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

/// Border Radius
/// Utilities for controlling the border radius of an element.

.rounded-none {
  border-radius: 0px;
}

.rounded-sm {
  border-radius: 0.125rem; /* 2px */
}

.rounded {
  border-radius: 0.25rem; /* 4px */
}

.rounded-md {
  border-radius: 0.375rem; /* 6px */
}

.rounded-lg {
  border-radius: 0.5rem; /* 8px */
}

.rounded-xl {
  border-radius: 0.75rem; /* 12px */
}

.rounded-2xl {
  border-radius: 1rem; /* 16px */
}

.rounded-3xl {
  border-radius: 1.5rem; /* 24px */
}

.rounded-full {
  border-radius: 50%;
}

/// Border style
.border-solid {
  border-style: solid;
}

/// Border size
.border-none {
  border: none;
}

.border {
  border-width: 1px;
}

@for $i from 0 through 8 {
  .border-#{$i} {
    border-width: #{$i}px;
  }
  .border-t-#{$i} {
    border-top-width: #{$i}px;
  }

  .border-r-#{$i} {
    border-right-width: #{$i}px;
  }

  .border-b-#{$i} {
    border-bottom-width: #{$i}px;
  }

  .border-l-#{$i} {
    border-left-width: #{$i}px;
  }
}

/// Border color
@for $index from 1 through 8 {
  $i: $index * 100;

  .border-gray-#{$i} {
    border-color: var(--gray-#{$i});
  }

  .border-t-gray-#{$i} {
    border-top-color: var(--gray-#{$i});
  }

  .border-r-gray-#{$i} {
    border-right-color: var(--gray-#{$i});
  }

  .border-b-gray-#{$i} {
    border-bottom-color: var(--gray-#{$i});
  }

  .border-l-gray-#{$i} {
    border-left-color: var(--gray-#{$i});
  }

  .border-y-gray-#{$i} {
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-color: var(--gray-#{$i});
  }
}
// @deprecated
@for $i from 1 through 7 {
  .border-bottom-gray-#{$i} {
    border-bottom-width: var(--border-width, 1px);
    border-bottom-style: solid;
    border-bottom-color: var(--gray-#{$i});
  }

  .border-y-gray-#{$i} {
    // top border
    border-top-width: 1px;
    border-top-style: solid;
    // bottom border
    border-bottom-width: 1px;
    border-bottom-style: solid;
    // all border
    border-color: var(--gray-#{$i});
  }

  .border-gray-#{$i} {
    border-width: 1px;
    border-style: solid;
    border-color: var(--gray-#{$i});
  }
}
